import React from 'react'

import ImgX from "../assets/about.webp"
import Produk1 from "../assets/produk1.jpg"
import Produk2 from "../assets/produk2.jpg"
import Produk3 from "../assets/produk3.jpg"

export default function Home() {
  return (
    <div>
<body class="bg-gray-100">
 
    <header class="bg-blue-500 p-4 text-white">
        <div class="container mx-auto flex justify-between items-center">
            <h1 class="text-2xl font-semibold">People-Nowadays</h1>
            <div>
                <a href="#" class="text-white hover:underline">Home</a>
                <a href="#" class="text-white hover:underline ml-4">Profile</a>
                <a href="#" class="text-white hover:underline ml-4">Logout</a>
            </div>
        </div>
    </header>

    
    <div class="container mx-auto flex mt-4">

        <aside class="w-1/4 p-4 bg-white border-r">
           
            <div class="flex items-center mb-4">
                <img src={Produk1} alt="User Avatar" class="w-12 h-12 rounded-full"/>
                <div class="ml-2">
                    <h2 class="font-semibold">Man Dimensions</h2>
                    <p class="text-gray-600">@demandingme</p>
                </div>
            </div>

           
            <div>
                <h3 class="text-lg font-semibold mb-2">Trending Topics</h3>
                <ul>
                    <li><a href="#" class="text-blue-500 hover:underline">#HealthyLiving</a></li>
                    <li><a href="#" class="text-blue-500 hover:underline">#Technology</a></li>
                    <li><a href="#" class="text-blue-500 hover:underline">#Travel</a></li>
                </ul>
            </div>
        </aside>

 
        <main class="w-1/2 p-4 bg-white border-r">
          
            <div class="mb-4">
                <textarea class="w-full p-2 border rounded" placeholder="What's happening?"></textarea>
                <div class="flex justify-between items-center mt-2">
                    <button class="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600">Tweet</button>
                    <p class="text-gray-500">140 characters remaining</p>
                </div>
            </div>

         
            <div class="border-b py-4">
                <div class="flex">
                    <img src={Produk1} alt="User Avatar" class="w-12 h-12 rounded-full"/>
                    <div class="ml-4">
                        <h2 class="font-semibold">Alodia Hello</h2>
                        <p class="text-gray-600">@comesfromthis99 - 3h ago</p>
                    </div>
                </div>
                <p class="mt-2">This is a sample tweet. #SampleTweet #PeopleNowadays</p>
            </div>

            <div class="border-b py-4">
                <div class="flex">
                    <img src={Produk1} alt="User Avatar" class="w-12 h-12 rounded-full"/>
                    <div class="ml-4">
                        <h2 class="font-semibold">Kelvin</h2>
                        <p class="text-gray-600">@kelvin3845 - 1h ago</p>
                    </div>
                </div>
                <p class="mt-2">Come on men, what's up guys?</p>
            </div>

            <div class="border-b py-4">
                <div class="flex">
                    <img src={Produk1} alt="User Avatar" class="w-12 h-12 rounded-full"/>
                    <div class="ml-4">
                        <h2 class="font-semibold">Bby #Whats?</h2>
                        <p class="text-gray-600">@bby909 - 1h ago</p>
                    </div>
                </div>
                <p class="mt-2">Let's Enjoy This</p>
            </div>

            <div class="border-b py-4">
                <div class="flex">
                    <img src={Produk1} alt="User Avatar" class="w-12 h-12 rounded-full"/>
                    <div class="ml-4">
                        <h2 class="font-semibold">Another People</h2>
                        <p class="text-gray-600">@people66778 - 5h ago</p>
                    </div>
                </div>
                <p class="mt-2">All right, already done my homework</p>
            </div>

            <div class="border-b py-4">
                <div class="flex">
                    <img src={Produk1} alt="User Avatar" class="w-12 h-12 rounded-full"/>
                    <div class="ml-4">
                        <h2 class="font-semibold">C U Later?</h2>
                        <p class="text-gray-600">@anonevertimes - 9h ago</p>
                    </div>
                </div>
                <p class="mt-2">Its time to sleep, isn't it?</p>
            </div>

           
        </main>

 
        <aside class="w-1/4 p-4 bg-white">
            <h3 class="text-lg font-semibold mb-2">Trending Topics</h3>
            <ul>
                <li><a href="#" class="text-blue-500 hover:underline">#HealthyLiving</a></li>
                <li><a href="#" class="text-blue-500 hover:underline">#Technology</a></li>
                <li><a href="#" class="text-blue-500 hover:underline">#Travel</a></li>
            
            </ul>
        </aside>
    </div>

   
    <footer class="bg-blue-500 text-white p-4">
        <div class="container mx-auto text-center">
            <p>&copy; 2023 People-Nowadays. All rights reserved.</p>
        </div>
    </footer>
</body>
    </div>
  )
}
